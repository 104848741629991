import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toast } from "react-toastify";

const filter = createFilterOptions({
	trim: true,
});

const NewTicketModal = ({ modalOpen, onClose, contact, callback }) => {
	const history = useHistory();

	const [options, setOptions] = useState([]);
	const [optionsFila, setOptionsFila] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingFila, setLoadingFila] = useState(false);
	const [loadingProc, setLoadingProc] = useState(false);

	const [loadingWp, setLoadingWp] = useState(false);
	const [searchParamWp, setSearchParamWp] = useState("");
	const [optionsWp, setOptionsWp] = useState([]);
	const [selectedWp, setSelectedWp] = useState(null);

	const [searchParam, setSearchParam] = useState("");
	const [searchParamFila, setSearchParamFila] = useState("");
	const [selectedContact, setSelectedContact] = useState(null);
	const [selectedFila, setSelectedFila] = useState(null);
	const [newContact, setNewContact] = useState({});
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		if(contact){
			setSelectedContact(contact)
			setSearchParam(contact?.name)
		}
	});

	useLayoutEffect(() => {
		setLoadingFila(true);
		setLoadingWp(true);
		const fetchContacts = async () => {
			try {
				const {data} = await api.get("queue", {
					params: { searchParamFila },
				});
				setOptionsFila(data);
				setLoadingFila(false);
			} catch (err) {
				setOptionsFila(false);
				toastError(err);
			}
		};

		const fetchwhatsapp = async () => {
			try {
				const { data } = await api.get("whatsapp", {
					params: { searchParamWp },
				});
				setOptionsWp(data);
				setLoadingWp(false);
			} catch (err) {
				setLoadingWp(false);
				toastError(err);
			}
		};

		fetchContacts();
		fetchwhatsapp();
	}, []);

	useEffect(() => {
		if (!modalOpen || searchParamFila.length < 3) {
			setLoadingFila(false);
			return;
		}
		setLoadingFila(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const {data} = await api.get("queue", {
						params: { searchParamFila },
					});
					setOptionsFila(data);
					setLoadingFila(false);
				} catch (err) {
					setOptionsFila(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParamFila, modalOpen]);


	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("contacts", {
						params: { searchParam },
					});
					setOptions(data.contacts);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen]);


	useEffect(() => {
		if (!modalOpen || searchParamWp.length < 3) {
			setLoadingWp(false);
			return;
		}
		setLoadingWp(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("whatsapp", {
						params: { searchParamWp },
					});
					setOptionsWp(data);
					setLoadingWp(false);
				} catch (err) {
					setLoadingWp(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParamWp, modalOpen]);

	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedContact(null);
	};

	const handleSaveTicket = async contactId => {

		if(!callback){
			if (!contactId) return;
			if (!selectedWp?.id) return;
			if (!selectedFila?.id) return;
		}else{
			if (!selectedFila?.id) return;
		}

		if(callback){
			callback({
				contactId: contactId,
				userId: user.id,
				status: "open",
				queueId: selectedFila?.id,
        		whatsappId: selectedWp?.id
			});
			onClose();
			return;
		}

		setLoadingProc(true);
		try {
			const { data: ticket } = await api.post("/tickets", {
				contactId: contactId,
				userId: user.id,
				status: "open",
				queueId: selectedFila?.id,
        		whatsappId: selectedWp?.id
			});
			history.push(`/tickets/${ticket.id}`);
		} catch (err) {
			toastError(err);
		}
		setLoadingProc(false);
		handleClose();
	};

	const handleSelectOption = (e, newValue) => {
		if (newValue?.number) {
			setSelectedContact(newValue);
		}
	};

	const handleSelectOptionFila = (e, newValue) => {
		if (newValue?.id) {
			setSelectedFila(newValue);
		}
	};

	const handleSelectOptionWp = (e, newValue) => {
		if (newValue?.id) {
			setSelectedWp(newValue);
		}
	};

	const handleCloseContactModal = () => {
		setContactModalOpen(false);
	};

	const handleAddNewContactTicket = contact => {
		handleSaveTicket(contact.id);
	};

	const createAddContactOption = (filterOptions, params) => {
		const filtered = filter(filterOptions, params);

		if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
			filtered.push({
				name: `${params.inputValue}`,
			});
		}

		return filtered;
	};

	const renderOption = option => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		// } else {
		// 	return `${i18n.t("newTicketModal.add")} ${option.name}`;
		}
	};

	const renderOptionFila = option => {
		if (option.name) {
			return `${option.name}`;
		}
	};

	const renderOptionLabel = option => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${option.name}`;
		}
	};

	return (
		<>
			<ContactModal
				open={contactModalOpen}
				initialValues={newContact}
				onClose={handleCloseContactModal}
				onSave={handleAddNewContactTicket}
			></ContactModal>
			<Dialog open={modalOpen} onClose={handleClose}>
				<DialogTitle id="form-dialog-title">
					{!contact ? i18n.t("newTicketModal.title") : ("Contato: " + contact.name)}
				</DialogTitle>

				{!contact ? 
				<DialogContent dividers>
					<Autocomplete
						options={options}
						loading={loading}
						style={{ width: 300 }}
						clearOnBlur
						autoHighlight
						freeSolo
						clearOnEscape
						getOptionLabel={renderOptionLabel}
						renderOption={renderOption}
						filterOptions={createAddContactOption}
						onChange={(e, newValue) => handleSelectOption(e, newValue)}
						renderInput={params => (
							<TextField
								{...params}
								label={i18n.t("newTicketModal.fieldLabel")}
								variant="outlined"
								autoFocus
								onChange={e => setSearchParam(e.target.value)}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</DialogContent> : null}

				<DialogContent dividers>
					<Autocomplete
						options={optionsFila}
						loading={loadingFila}
						style={{ width: 300 }}
						clearOnBlur
						autoHighlight
						freeSolo
						clearOnEscape
						getOptionLabel={renderOptionLabel}
						renderOption={renderOptionFila}
						filterOptions={createAddContactOption}
						onChange={(e, newValue) => handleSelectOptionFila(e, newValue)}
						renderInput={params => (
							<TextField
								{...params}
								label={"Qual Fila de Atendimento?"}
								variant="outlined"
								onChange={e => setSearchParamFila(e.target.value)}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loadingFila ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</DialogContent>


				{!callback ? 
				<DialogContent dividers>
					<Autocomplete
						options={optionsWp}
						loading={loadingWp}
						style={{ width: 300 }}
						clearOnBlur
						autoHighlight
						freeSolo
						clearOnEscape
						getOptionLabel={renderOptionLabel}
						renderOption={renderOptionFila}
						filterOptions={createAddContactOption}
						onChange={(e, newValue) => handleSelectOptionWp(e, newValue)}
						renderInput={params => (
							<TextField
								{...params}
								label={"Por qual Conexão (Whatsapp) quer conversar?"}
								variant="outlined"
								onChange={e => setSearchParamWp(e.target.value)}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loadingWp ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</DialogContent>: null}

				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loadingProc}
						variant="outlined"
					>
						{i18n.t("newTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="button"
						disabled={callback ? (!selectedFila) : (
							!selectedContact ||
							!selectedFila ||
							!selectedWp
						)}
						onClick={() => handleSaveTicket(selectedContact.id)}
						color="primary"
						loading={loadingProc}
					>
						{i18n.t("newTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default NewTicketModal;
